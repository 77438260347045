import React, { Component } from "react";
import {
  withStyles,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  DialogActions,
  Button,
  CircularProgress,
  Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import { withSnackbar } from "notistack";

import AddSellerForm from "../form/AddSellerForm";
import { TransitionUp } from "../utils/transitions/TransitionUp";
import CompanyService from "../../services/CompanyService";
import FormStepper from "../FormStepper";
import ManageUserFeaturesForm from "../form/ManageUserFeaturesForm";

const STEPS = ["Informations", "Droits"];

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  padding: {
    padding: theme.spacing(3)
  }
});

const emptySeller = {
  firstname: "",
  lastname: "",
  email: "",
  job: "",
  phone: "",
  company: ""
};

class AddSellerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      activeStep: 0,
      seller: emptySeller,
      acls: [],
      profile: "NONE"
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      open: props.open,
      loading: false,
      activeStep: 0,
      seller: emptySeller,
      acls: [],
      profile: "NONE"
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleTmpSubmitUser = seller => {
    this.setState({ seller, activeStep: 1 });
  };

  handleSubmit = () => {
    this.setState({ loading: true }, () => {
      const { seller } = this.state;
      CompanyService.addUser(seller)
        .then(res => {
          const { profile } = this.state;
          if (profile !== "NONE") {
            this.updateProfile(res.id).then(() => {
              const { onAddOrUpdate } = this.props;
              onAddOrUpdate(res);
              this.setState({ open: false });
            });
          } else {
            this.updateAcls(res.id).then(() => {
              const { onAddOrUpdate } = this.props;
              onAddOrUpdate(res);
              this.setState({ open: false });
            });
          }
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("L'utilisateur a été ajouté", { variant: "success" });
        })
        .catch(error => {
          if (error.status === 409) {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar("L'utilisateur existe déjà", { variant: "info" });
          } else {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar(
              error.status === 400
                ? "Les informations de l'utilisateur sont incorrectes"
                : "Une erreur est survenue lors de la création de l'utilisateur",
              {
                variant: "error"
              }
            );
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  updateProfile = async userId => {
    const { profile } = this.state;
    const res = await CompanyService.setUserProfile(userId, profile === "NONE" ? null : profile)
      .then(() => true)
      .catch(() => false);
    return res;
  };

  updateAcls = async userId => {
    const { acls } = this.state;
    const res = await CompanyService.setUserAcls(userId, acls)
      .then(() => true)
      .catch(() => false);
    return res;
  };

  handlePrevious = () => {
    this.setState({ activeStep: 0 });
  };

  handleChange = activeStep => {
    this.setState({ activeStep });
  };

  render() {
    const { classes } = this.props;
    const { open, loading, activeStep, seller, acls, profile } = this.state;
    const disableSubmit = loading || (profile === "NONE" && ((acls && acls.length === 0) || acls === null));

    return (
      <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={TransitionUp}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Ajouter un utilisateur
            </Typography>
          </Toolbar>
        </AppBar>
        {open && (
          <Grid container justify="center">
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
              <FormStepper activeStep={activeStep} steps={STEPS} />
            </Grid>
            <Grid item xs={12} md={3} />

            {activeStep === 0 && (
              <Grid item xs={12} md={6}>
                <AddSellerForm
                  handleSubmit={this.handleTmpSubmitUser}
                  handleCancel={this.handleClose}
                  isSubmitting={loading}
                  seller={seller}
                  submitBtnText="Suivant"
                />
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid container justify="center" className={classes.padding}>
                <Grid item xs={12} md={6}>
                  <ManageUserFeaturesForm
                    acls={acls}
                    setAcls={_acls => this.setState({ acls: _acls })}
                    profile={profile}
                    setProfile={_profile => this.setState({ profile: _profile })}
                  />
                </Grid>
                <Grid container justify="flex-end">
                  <DialogActions>
                    <Button onClick={this.handlePrevious} color="primary">
                      Retour
                    </Button>
                    <Button
                      onClick={this.handleSubmit}
                      color="primary"
                      variant="contained"
                      autoFocus
                      disabled={disableSubmit}
                      style={{ position: "relative" }}
                    >
                      {loading && (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            marginTop: -10,
                            marginLeft: -10,
                            left: "50%",
                            zIndex: 2
                          }}
                        >
                          <CircularProgress size={20} />
                        </div>
                      )}
                      Ajouter
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(AddSellerDialog));
