import axios from "axios";
import CookieService from "../services/CookieService";

export const METHOD = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH"
};

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.API_URL
});

/**
 * Request Wrapper with default success/error actions
 */
const request = async options => {
  const onSuccess = response => response.data;

  const onError = error => {
    console.debug("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.debug("Status:", error.response.status);
      console.debug("Data:", error.response.data);
      console.debug("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.debug("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options)
    .then(onSuccess)
    .catch(onError);
};

client.interceptors.request.use(
  config => {
    const newConfig = config;
    newConfig.headers.authorization = CookieService.getCookie("logAs")
      ? `Bearer ${CookieService.getCookie("logAs")}`
      : CookieService.getCookie("token")
      ? `Bearer ${CookieService.getCookie("token")}`
      : "";
    return newConfig;
  },
  error => Promise.reject(error)
);

client.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response.status === 419) {
      return request({
        url: "/auth/refresh",
        method: METHOD.POST
      }).then(response => {
        CookieService.setCookie("token", response.token, 1);
        originalRequest.headers.authorization = `Bearer ${response.token}`;
        return axios(originalRequest);
      });
    }
    if (error.response.status === 401 && error.response.data && error.response.data.error === "Unauthorized.")
      window.location = "/";

    // Do something with response error
    return Promise.reject(error);
  }
);

export default request;
