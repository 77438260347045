import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

import { useQuery } from "@tanstack/react-query";
import { compareVersions } from "../utils/update";
import packageJson from "../../package.json";

import { getVersion } from "../services/CommonService";

export default function UpdateAlert() {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updateDenied, setUpdateDenied] = useState(false);

  useQuery({
    queryKey: ["update-alert"],
    queryFn: () =>
      getVersion()
        .then(_data => {
          setUpdateAvailable(compareVersions(_data.version, packageJson.version) === 1);
          setUpdateDenied(false);
          return _data;
        })
        .catch(e => {
          setUpdateAvailable(false);
          setUpdateDenied(false);
          return e;
        }),
    enabled: (updateDenied && updateAvailable) || (!updateDenied && !updateAvailable),
    retry: false,
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 15, // 1 hour
    cacheTime: 1000 * 60 * 15, // 1 hour
    refetchInterval: 1000 * 60 * 60 * 6 // 6 hours
  });

  return (
    <div>
      <Dialog open={updateAvailable && !updateDenied} onClose={() => setUpdateDenied(true)}>
        <DialogTitle>Mise à jour disponible</DialogTitle>
        <DialogContent>
          <DialogContentText>Une mise à jour est disponible, veuillez rafraîchir la page.</DialogContentText>
          <DialogContentText>
            En rafraîchissant la page, vous pourrez profiter de nouvelles fonctionnalités et améliorations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateAvailable(false)}>Pas maintenant</Button>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
            Rafraîchir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
