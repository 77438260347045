import { Box, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React, { Fragment } from "react";

const useStyles = makeStyles(() => ({
  container: { display: "flex", flexDirection: "row" },
  labelContainer: { flex: 1, display: "flex", alignItems: "center" },
  inputContainer: { flex: 1 }
}));

function CompanyMailTemplate({ label, name, templates, onChange }) {
  const classes = useStyles();
  return (
    <Fragment>
      <Grid item xs={12}>
        <Box className={classes.container}>
          <div className={classes.labelContainer}>
            <Typography variant="body2">{label}</Typography>
          </div>
          <div className={classes.inputContainer}>
            <TextField
              size="small"
              variant="outlined"
              name={name}
              value={templates && templates[name] ? templates[name] : ""}
              onChange={onChange}
              fullWidth
            />
          </div>
        </Box>
      </Grid>
    </Fragment>
  );
}

export default CompanyMailTemplate;
