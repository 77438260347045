import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  Tooltip,
  Box
  // FormControl,
  // RadioGroup,
  // Radio,
  // FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import InfoIcon from "@material-ui/icons/Info";
import { useSnackbar } from "notistack";

import { inject, observer } from "mobx-react";
import NotFoundPage from "./NotFoundPage";
import SignatureDocumentStep from "../components/signature/SignatureDocumentStep";
import SignatureStepperManager from "../components/signature/SignatureStepperManager";
import SignatureStepper from "../components/signature/SignatureStepper";
import SignatureSignatories from "../components/signature/SignatureSignatories";
import SignatureTypeSelection from "../components/signature/SignatureTypeSelection";
import ValidatorService from "../services/ValidatorService";
import SignatureService from "../services/SignatureService";
import { privateRoutes } from "../routes";
import { yousignDefaultSignatureType } from "../services/YouSignService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing()
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  loaderWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  stepContainer: {
    marginTop: theme.spacing(2)
  },
  paperWithSpacing: {
    padding: theme.spacing(2)
  },
  inputWithTooltip: {
    display: "flex",
    alignItems: "center"
  },
  infoIcon: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing()
  }
}));

function SignaturePage({ sessionStore, match, history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [signatories, setSignatories] = useState([]);
  const [includeUserAsSignatory, setIncludeUserAsSignatory] = useState(false);
  const [sendInvitations, setSendInvitations] = useState(false);
  const [userSignatureType, setUserSignatureType] = useState(yousignDefaultSignatureType(sessionStore));
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);
  const [subject, setSubject] = useState("");
  const [selectedSignatureType, setSelectedSignatureType] = useState("PAD");
  const [subjectPlaceholder, setSubjectPlaceholder] = useState("Objet");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const editionMode = useMemo(() => match && match.params && match.params.signatureId, [match]);

  const hasAccess = useMemo(
    () =>
      sessionStore.userHasAccess({
        requiredAcls: editionMode ? ["DOC_SIGNATURE_WRITE", "DOC_SIGNATURE_MY"] : ["DOC_SIGNATURE_MY"],
        aclType: "oneof",
        requiredFeatures: ["DOC_SIGNATURE"]
      }),
    [sessionStore, editionMode]
  );

  useEffect(() => {
    if (editionMode) {
      SignatureService.getSignature(match.params.signatureId)
        .then(res => {
          setSignatories(
            res.signatories
              .filter(signatory => signatory.contact !== null)
              .map(signatory => ({
                ...signatory.contact,
                signatureType: signatory.signatureType || yousignDefaultSignatureType(sessionStore)
              }))
          );
          setSubject(res.subject);
          setSelectedSignatureType(res.signatureType);
          const userAsSigner = res.signatories.find(signatory => signatory.contact !== null);
          setIncludeUserAsSignatory(!!userAsSigner);
          setSendInvitations(!!res.sendInvitations);
          setUserSignatureType(userAsSigner ? userAsSigner.signatureType : yousignDefaultSignatureType(sessionStore));
          setActiveStep(0);
          setIsLoading(false);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération de la signature", { variant: "error" });
          history.push(privateRoutes.admin.signatures.listing.path);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleImport = useCallback(_file => {
    setFile(_file);
    setSubjectPlaceholder(_file.name.slice(0, -4));
  }, []);
  const handleTypeChange = useCallback(newValue => setSelectedSignatureType(newValue), []);
  const handleNameChange = useCallback(e => setSubject(e.target.value), []);
  const toggleIncludeUser = useCallback(() => setIncludeUserAsSignatory(value => !value), []);
  const toggleSendInvitations = useCallback(() => setSendInvitations(value => !value), []);

  const steps = useMemo(() => {
    if (sessionStore.user.company.signatureProvider === "YOUSIGN") {
      return [
        {
          label: "Document",
          canNext: () => !!file || editionMode,
          canPrevious: () => false
        },
        {
          label: "Signataires",
          canNext: () => signatories.length > 0,
          // canPrevious: () => !editionMode
          canPrevious: () => true,
          nextLabel: "Terminer"
        }
      ];
    }
    return [
      {
        label: "Document",
        canNext: () => !!file || editionMode,
        canPrevious: () => false
      },
      {
        label: "Signataires",
        canNext: () => signatories.length > 0,
        // canPrevious: () => !editionMode
        canPrevious: () => true
      },
      {
        label: "Validation",
        canNext: () => true,
        canPrevious: () => true,
        nextLabel: "Terminer"
      }
    ];
  }, [file, signatories, sessionStore]);

  const handleSubmit = () => {
    setIsProcessing(true);
    if (editionMode) {
      SignatureService.patchSignature({
        document: file,
        subject: subject || file.name,
        contacts: signatories,
        mySignature: includeUserAsSignatory,
        mySignatureType: userSignatureType,
        signatureType: selectedSignatureType,
        sendInvitations,
        signatureId: match.params.signatureId // CURRENT ID
      })
        .then(() => {
          enqueueSnackbar("La signature a bien été mise à jour", { variant: "success" });
          setIsProcessing(false);
          history.push(privateRoutes.admin.signatures.listing.path);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la mise à jour de la signature", { variant: "error" });
          setIsProcessing(false);
        });
    } else {
      SignatureService.postSignature({
        document: file,
        subject: subject || file.name,
        contacts: signatories,
        mySignature: includeUserAsSignatory,
        mySignatureType: userSignatureType,
        signatureType: selectedSignatureType,
        sendInvitations
      })
        .then(() => {
          enqueueSnackbar("La signature a bien été créée", { variant: "success" });
          setIsProcessing(false);
          history.push(privateRoutes.admin.signatures.listing.path);
        })
        .catch(e => {
          if (e.status === 413) {
            enqueueSnackbar("Une erreur est survenue : le poids du fichier PDF est trop important", {
              variant: "error"
            });
          } else {
            enqueueSnackbar("Une erreur est survenue lors de la création de la signature", { variant: "error" });
          }

          setIsProcessing(false);
        });
    }
  };

  const availablesTypes = useMemo(() => {
    let canOTP = true;
    const types = ["PAD"];
    signatories.forEach(signatory => {
      if (!ValidatorService.validateMobilePhone(signatory.phone)) canOTP = false;
    });
    if (includeUserAsSignatory && !ValidatorService.validateMobilePhone(sessionStore.user.phone)) canOTP = false;
    if (canOTP) types.push("OTP");
    return types;
  }, [signatories, includeUserAsSignatory]);

  useEffect(() => {
    if (!availablesTypes.includes(selectedSignatureType)) setSelectedSignatureType();
  }, [signatories, includeUserAsSignatory, availablesTypes]);

  if (!hasAccess) return <NotFoundPage />;

  if (isLoading) {
    return (
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={12} className={classes.loaderWrapper}>
          <CircularProgress size={50} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item xs={12} className={classes.title}>
        <Typography variant="h4">
          {editionMode ? "Modification d'une signature" : "Initialisation d'une signature"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <SignatureStepper activeStep={activeStep} steps={steps} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {activeStep === 0 && (
          <Grid container spacing={2} className={classes.stepContainer}>
            <Grid item xs={12}>
              <Paper className={classes.paperWithSpacing}>
                <Typography variant="body1" style={{ padding: 8 }}>
                  Bienvenue dans le parcours de signature d&apos;un document. Ici, vous pouvez charger un document au
                  format PDF pour le faire signer à vos clients que ce soit via une signature par code SMS ou
                  &quot;manuscrite&quot;.
                </Typography>
                <Typography variant="body2" style={{ padding: 8 }}>
                  A noter que pour faire une signature par code SMS, vous (si vous vous incluez) et vos clients devront
                  avoir des numéros de téléphone mobiles valides.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperWithSpacing}>
                <div className={classes.inputWithTooltip}>
                  <TextField
                    id="signature-subject"
                    label="Objet"
                    placeholder={subjectPlaceholder}
                    variant="outlined"
                    value={subject}
                    onChange={handleNameChange}
                    fullWidth
                  />
                  <Tooltip title="L'objet pourra vous être utile pour identifier plus facilement votre document. Par défaut il vaudra le nom du fichier chargé si vous ne l'avez pas renseigné">
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paperWithSpacing}>
                <SignatureDocumentStep
                  onImport={handleImport}
                  file={file}
                  editionMode={editionMode}
                  signatureId={match && match.params && match.params.signatureId}
                />
              </Paper>
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && (
          <Box className={classes.stepContainer}>
            <SignatureSignatories
              signatories={signatories}
              updateSignatories={setSignatories}
              includeUser={includeUserAsSignatory}
              toggleIncludeUser={toggleIncludeUser}
              sendInvitations={sendInvitations}
              toggleSendInvitations={toggleSendInvitations}
              userSignatureType={userSignatureType}
              setUserSignatureType={setUserSignatureType}
            />
          </Box>
        )}
        {activeStep === 2 && (
          <Grid container spacing={2} className={classes.stepContainer}>
            <Grid item xs={12}>
              <Paper className={classes.paperWithSpacing}>
                <SignatureTypeSelection
                  availablesTypes={availablesTypes}
                  selectedType={selectedSignatureType}
                  onChange={handleTypeChange}
                />
              </Paper>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} className={classes.stepContainer}>
          <Grid item xs={12}>
            <Paper className={classes.paperWithSpacing}>
              <SignatureStepperManager
                isProcessing={isProcessing}
                currentStep={activeStep}
                updateStep={_step => setActiveStep(_step)}
                steps={steps}
                onSuccess={handleSubmit}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withRouter(inject("sessionStore")(observer(props => <SignaturePage {...props} />)));
