import React, { Component, Fragment } from "react";
import { Grid, Paper, withStyles, CircularProgress, Typography, Button } from "@material-ui/core";
import { PieChart, Pie, ResponsiveContainer, Legend } from "recharts";
import withWidth from "@material-ui/core/withWidth";
import { inject, observer } from "mobx-react";

import EstimatesList from "./EstimatesList";
import EstimatesWidgets from "./EstimatesWidgets";
import { formatCurrency } from "../../../utils/numberFormat";
import { ESTIMATE_STATUS } from "../../../utils/status";
import ClientService from "../../../services/ClientService";

@inject("sessionStore")
@observer
class ContactDashboardEstimates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      estimates: [],
      estimatesData: {},
      estimatesCount: 0,
      turnover: 0,
      turnoverWithoutVates: 0
    };
  }

  componentDidMount() {
    this.getDashboardData();
  }

  componentDidUpdate(prevProps) {
    const { from, to } = this.props;
    if (prevProps.from !== from || prevProps.to !== to) {
      this.getDashboardData();
    }
  }

  getDashboardData = () => {
    const { from, to, clientId } = this.props;
    this.setState({ loading: true });
    ClientService.getEstimateDashboard(clientId, from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD"))
      .then(resp => {
        const { estimates, estimatesData, estimatesCount } = resp;
        let turnover = 0;
        let turnoverWithoutVates = 0;
        const graphData = [];
        Object.keys(estimatesData).forEach(status => {
          if (status !== "CANCEL" && status !== "REFUSED") {
            turnoverWithoutVates += Number.parseFloat(estimatesData[status].amountWithoutTVA);
            turnover += Number.parseFloat(estimatesData[status].amount);
            graphData.push({
              name: ESTIMATE_STATUS[status].labelPlural,
              value: estimatesData[status].amountWithoutTVA,
              fill: ESTIMATE_STATUS[status].color
            });
          }
        });
        this.setState({ estimates, estimatesData, turnover, graphData, turnoverWithoutVates, estimatesCount });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  canPreviewEstimates = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["ESTIMATE_READ", "ESTIMATE_MY"],
      aclType: "oneof",
      requiredFeatures: ["ESTIMATE"]
    });
  };

  handlePreviewEstimates = () => {
    const { handleDetailsNavigation } = this.props;
    handleDetailsNavigation("/devis");
  };

  render() {
    const { loading, graphData, estimates, estimatesData, turnover, turnoverWithoutVates, estimatesCount } = this.state;
    const { classes, clientId } = this.props;

    return (
      <Fragment>
        {loading ? (
          <div className={classes.centerizedLoader}>
            <CircularProgress size={80} />
          </div>
        ) : (
          <Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} className={classes.graphAndTurnover}>
                <div className={classes.root}>
                  <Grid container>
                    <div className={classes.turnover}>
                      <div style={{ flex: 2 }}>
                        <Typography variant="body1" className={classes.turnoverTitle}>
                          Devis
                        </Typography>
                        <Typography variant="h5">{estimatesCount}</Typography>
                      </div>
                      <div style={{ flex: 2 }}>
                        <Typography variant="body1" className={classes.turnoverTitle}>
                          Chiffre d&apos;affaires
                        </Typography>
                        <Typography variant="h5">
                          {formatCurrency(turnoverWithoutVates)}{" "}
                          {turnover > 0 && (
                            <span style={{ display: "block", fontSize: "1.2rem" }}>
                              ({formatCurrency(turnover)} TTC)
                            </span>
                          )}
                        </Typography>
                      </div>
                    </div>
                    <Grid item xs={12} className={classes.graphContainer}>
                      {turnover > 0 ? (
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Legend wrapperStyle={{ marginTop: 10 }} />
                            <Pie
                              legendType="none"
                              isAnimationActive={false}
                              data={graphData}
                              dataKey="value"
                              innerRadius={0}
                              outerRadius={90}
                              label={data => formatCurrency(data.payload.value)}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: 200
                          }}
                        >
                          <Typography variant="body2">Aucune donnée à afficher</Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12} md={6} className={classes.widgets}>
                <div className={classes.root}>
                  <EstimatesWidgets estimatesData={estimatesData} />
                </div>
              </Grid>
            </Grid>

            {this.canPreviewEstimates() && (
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.table}>
                      <EstimatesList estimates={estimates} hideContactColumn={!!clientId} />
                    </Paper>
                  </Grid>
                  {estimates.length > 0 && (
                    <Grid item xs={12} align="center">
                      <Button variant="contained" color="primary" onClick={this.handlePreviewEstimates}>
                        Voir {estimates.length > 1 ? "les devis" : "le devis"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2)
  },
  table: {
    overflowX: "auto"
  },
  title: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing()
  },
  centerizedLoader: {
    display: "flex",
    minHeight: 100,
    paddingTop: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center"
  },
  graphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  turnover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    padding: theme.spacing(3),
    width: "100%",
    textAlign: "center"
  },
  turnoverTitle: {
    marginBottom: theme.spacing()
  },
  graphAndTurnover: {
    order: 1,
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      order: 2
    }
  },
  widgets: {
    order: 2,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      order: 1
    }
  }
});

export default withWidth()(withStyles(styles)(ContactDashboardEstimates));
