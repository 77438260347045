import cmp from "semver-compare"; // eslint-disable-line

const VERSION_KEY = "public_version";
const VERSION_TIMESTAMP_KEY = "public_version_timestamp";

const setVersionFromHeader = response => {
  if (response.headers && response.headers["x-version"]) {
    localStorage.setItem(VERSION_KEY, response.headers["x-version"]);
    localStorage.setItem(VERSION_TIMESTAMP_KEY, new Date().getTime());
    window.dispatchEvent(new Event("storage"));
  }
};

const compareVersions = (a, b) => {
  if (!a || !b) {
    return 0;
  }
  return cmp(a, b);
};

export { setVersionFromHeader, compareVersions, VERSION_KEY, VERSION_TIMESTAMP_KEY };
