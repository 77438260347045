import moment from "moment";
import request, { METHOD } from "../utils/request";

class EstimateService {
  static STATUS = {
    DRAFT: "Brouillon",
    VALIDATED: "Validée",
    SENT: "Envoyée",
    PAID: "Payée",
    CANCEL: "Annulée par avoir",
    CREDIT_NOTE: "Avoir"
  };

  static signatureDeadline = estimate => {
    if (estimate.signatureRequestExpirationDate) {
      return moment(estimate.signatureRequestExpirationDate);
    }
    if (estimate.deadlineSignature) {
      return moment(estimate.deadlineSignature);
    }
    return moment(estimate.createAt).add(100, "hours");
  };

  static signatureExpired = estimate => EstimateService.signatureDeadline(estimate).isBefore(moment(), "second");

  static getEstimates({ page = 0, sort = "estimate.number", order = "DESC", filter = {} }) {
    const { q = "", userId = "", status = "", from = "", to = "", client = "" } = filter;
    return request({
      url: `/estimates?page=${page}&sort=${sort}&order=${order}&q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}`,
      method: METHOD.GET
    });
  }

  static getEstimate(id) {
    return request({
      url: `/estimates/${id}`,
      method: METHOD.GET
    });
  }

  static intoInvoice(id) {
    return request({
      url: `/estimates/${id}/invoice`,
      method: METHOD.POST
    });
  }

  static duplicateEstimate(id) {
    return request({
      url: `/estimates/${id}/duplicate`,
      method: METHOD.POST
    });
  }

  static postEstimate(estimate) {
    return request({
      url: "/estimates",
      method: METHOD.POST,
      data: estimate
    });
  }

  static patchEstimate(estimate) {
    return request({
      url: `/estimates/${estimate.id}`,
      method: METHOD.PATCH,
      data: estimate
    });
  }

  static sendForSignature(estimate) {
    return request({
      url: `/estimates/${estimate.id}/initSign`,
      method: METHOD.POST
    });
  }

  static sendForSignatureWithYouSign(estimate, data) {
    return request({
      url: `/estimates/${estimate.id}/initYouSign`,
      method: METHOD.POST,
      data
    });
  }

  static getEstimateSignature(estimateId) {
    return request({
      url: `/estimates/${estimateId}/signature`,
      method: METHOD.GET
    });
  }

  static getSignStatus(estimateId) {
    return request({
      url: `/estimates/${estimateId}/sign`,
      method: METHOD.GET
    });
  }

  static sendSignCode(estimateId) {
    return request({
      url: `/estimates/${estimateId}/genOTP`,
      method: METHOD.GET
    });
  }

  static checkSignCode(estimateId, code) {
    return request({
      url: `/estimates/${estimateId}/checkOTP/${code}`,
      method: METHOD.POST
    });
  }

  static deleteEstimate(estimate) {
    return request({
      url: `/estimates/${estimate.id}`,
      method: METHOD.DELETE
    });
  }

  static sendEstimate(estimate) {
    return request({
      url: `/estimates/${estimate.id}/mail`,
      method: METHOD.POST
    });
  }

  static downloadPDF(estimate) {
    return request({
      url: `/estimates/${estimate.id}/downloadPDF`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static convertIntoEstimateObject(estimate) {
    const {
      amount,
      clientAddress,
      clientBusinessName,
      clientCity,
      clientInformation,
      clientEmail,
      clientLastname,
      clientFirstname,
      clientPhone,
      clientType,
      clientZipCode,
      currency = "EUR",
      computedDiscount,
      discount,
      discountMethod,
      information,
      nature,
      paymentCondition,
      paymentMode,
      totalPriceWithTVA,
      totalPriceWithoutTVA
    } = estimate;
    const invoiceInformation = JSON.parse(information);
    const convertedInvoice = {
      amount,
      client: {
        address: clientAddress,
        businessName: clientBusinessName,
        city: clientCity,
        comments: clientInformation,
        email: clientEmail,
        firstname: clientFirstname,
        lastname: clientLastname,
        phone: clientPhone,
        type: clientType,
        zipCode: clientZipCode
      },
      computedDiscount,
      currency,
      discount,
      discountMethod,
      information: invoiceInformation,
      nature,
      paymentCondition,
      paymentMode,
      totalPriceWithTVA,
      totalPriceWithoutTVA
    };
    return convertedInvoice;
  }

  static sendSignEstimateMail(estimate) {
    return request({
      url: `/estimates/${estimate.id}/sendSignMail`,
      method: METHOD.POST
    });
  }

  static addAdditionalDocument(estimate, type) {
    return request({
      url: `/estimates/${estimate.id}/additionalDocuments`,
      method: METHOD.POST,
      data: { type }
    });
  }

  static exportEstimates({ filter = {} }) {
    const { q = "", userId = "", status = "", from = "", to = "", client = "" } = filter;
    return request({
      url: `/estimates/exportPDF?q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}`,
      method: METHOD.GET,
      responseType: "arraybuffer"
    });
  }

  static exportEstimatesAsCsv({ filter = {} }) {
    const { q = "", userId = "", status = "", from = "", to = "", client = "" } = filter;
    return request({
      url: `/estimates/exportToCSV?q=${q}&userId=${userId}&status=${status}&from=${from}&to=${to}&contactId=${client}`,
      method: METHOD.GET
    });
  }
}

export default EstimateService;
