import { useQuery } from "@tanstack/react-query";
import { inject, observer } from "mobx-react";
import React from "react";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import CompanyService from "../services/CompanyService";

function FilterUserInput({ sessionStore, filter, onChange }) {
  const userIsAdmin = sessionStore.isCompanyAdmin();

  const { data, error } = useQuery({
    queryKey: ["company_users"],
    queryFn: () => CompanyService.getSimplifiedUsers(),
    refetchOnWindowFocus: false,
    cacheTime: 50000,
    staleTime: 50000,
    enabled: userIsAdmin,
    retry: (failureCount, _error) => _error.status !== 401 && _error.status !== 400 && failureCount < 3
  });

  if (!userIsAdmin) {
    return null;
  }

  return (
    <div>
      {data ? (
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Utilisateur</InputLabel>
          <Select
            value={filter.userId}
            onChange={onChange}
            variant="outlined"
            fullWidth
            style={{ textAlign: "left" }}
            placeholder=""
            label="Utilisateur"
          >
            <MenuItem value="">
              <em> -- Tous les utilisateurs --</em>
            </MenuItem>
            {data.map(({ id, firstname, lastname }) => (
              <MenuItem key={id} value={id}>
                {`${firstname} ${lastname.toUpperCase()}${id === sessionStore.user.id ? " (moi)" : ""}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : error ? (
        "Une erreur est survenue"
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default inject("sessionStore")(observer(props => <FilterUserInput {...props} />));
