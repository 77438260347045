import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  withStyles
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import { withSnackbar } from "notistack";
import ListAltIcon from "@material-ui/icons/ListAlt";

import fileDownload from "js-file-download";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";
import ClientsListFilter from "./ClientsListFilter";
import TableAction from "../TableAction";
import TableSortableHeader from "../table/TableSortableHeader";
import ClientService from "../../services/ClientService";
import AddEditClientDialog from "../dialog/AddEditClientDialog";
import { privateRoutes } from "../../routes";

const ROWS_PER_PAGE = 50;

@inject("sessionStore")
@observer
class ClientsList extends Component {
  constructor(props) {
    super(props);
    this.defaultFilter = {
      q: "",
      userId: "ALL",
      installationDateFrom: null,
      installationDateTo: null,
      reminderDateFrom: null,
      reminderDateTo: null
    };
    this.state = {
      clients: null,
      currentClient: null,
      currentPage: 0,
      currentSort: null,
      filter: this.defaultFilter,
      gonnaBeRefreshed: false,
      openFormModal: false,
      totalCount: 0,
      menuOpenFor: null,
      hideCreator: true,
      isExporting: false,
      anchorEl: null
    };
    const { sessionStore } = props;
    this.canToggleShowCreator = sessionStore.userHasAccess({
      requiredAcls: ["CONTACT_READ"],
      requiredFeatures: ["CONTACT"]
    });
  }

  componentDidMount() {
    this.fetchClients();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangePage = (event, page) => {
    if (this._isMounted) {
      this.setState({ currentPage: page }, () => {
        this.fetchClients();
      });
    }
  };

  handleDeactivation = client => {
    ClientService.manageClient(client, "DEACTIVATE")
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Le client a été désactivé", { variant: "success" });
        this.fetchClients();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la désactivation du client", { variant: "error" });
      });
  };

  handleActivation = client => {
    ClientService.manageClient(client, "ACTIVATE")
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Le client a été activé", { variant: "success" });
        this.fetchClients();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'activation du client", { variant: "error" });
      });
  };

  handleEdit = client => {
    // this.setState({ currentClient: client, openFormModal: true });
    const { history } = this.props;
    history.push(`/clients/${client.id}/modification`);
  };

  canEstimate = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["ESTIMATE_MY"],
      requiredFeatures: ["ESTIMATE"]
    });
  };

  canInvoice = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_MY"],
      requiredFeatures: ["INVOICE"]
    });
  };

  canOrderForm = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["ORDER_MY"],
      requiredFeatures: ["ORDER"]
    });
  };

  // canAdminClients = () => {
  //   const { sessionStore } = this.props;
  //   return sessionStore.userHasAccess({
  //     requiredAcls: ["CONTACT_WRITE"],
  //     requiredFeatures: ["CONTACT"]
  //   });
  // };

  renderMenu = client => {
    const { sessionStore, contactsStatus } = this.props;
    const canManage =
      client.user.id === sessionStore.user.id ||
      sessionStore.userHasAccess({
        requiredAcls: ["CONTACT_WRITE"],
        requiredFeatures: ["CONTACT"]
      });
    return (
      <TableAction
        onOpen={() => {
          this.setState({ menuOpenFor: client.id });
        }}
        onClose={() => {
          this.setState({ menuOpenFor: null });
        }}
      >
        {this.canOrderForm() && contactsStatus === "ACTIVE" && (
          <MenuItem
            key="menuitem-orderform"
            component={Link}
            to={{ pathname: privateRoutes.common.orderForms.creationForm.path, state: { client } }}
          >
            Créer un Bon de commande
          </MenuItem>
        )}
        {this.canEstimate() && contactsStatus === "ACTIVE" && (
          <MenuItem
            key="menuitem-estimate"
            component={Link}
            to={{ pathname: privateRoutes.common.estimates.creationForm.path, state: { client } }}
          >
            Créer un Devis
          </MenuItem>
        )}
        {this.canInvoice() && contactsStatus === "ACTIVE" && (
          <MenuItem
            key="menuitem-invoice"
            component={Link}
            to={{ pathname: privateRoutes.admin.invoices.creationForm.path, state: { client } }}
          >
            Créer une Facture
          </MenuItem>
        )}
        {(this.canInvoice() || this.canOrderForm() || this.canEstimate()) &&
          canManage &&
          contactsStatus === "ACTIVE" && <Divider />}
        <MenuItem
          key="menuitem-preview"
          component={Link}
          to={{ pathname: privateRoutes.common.clientDetails.path.replace(":clientId", client.id) }}
        >
          Voir la fiche
        </MenuItem>
        {canManage && contactsStatus === "ACTIVE" && (
          <MenuItem key="menuitem-edit" onClick={() => this.handleEdit(client)}>
            Modifier
          </MenuItem>
        )}
        {canManage && client.isActive && (
          <ConfirmationDialog key="menuitem-delete" situationText="Êtes-vous sûr de vouloir désactiver ce client ?">
            {confirmBeforeAct => (
              <MenuItem
                onClick={e => {
                  stopPropagationForConfirmation(
                    e,
                    confirmBeforeAct(() => this.handleDeactivation(client))
                  );
                }}
              >
                Désactiver
              </MenuItem>
            )}
          </ConfirmationDialog>
        )}
        {canManage && !client.isActive && <MenuItem onClick={() => this.handleActivation(client)}>Activer</MenuItem>}
      </TableAction>
    );
  };

  handleSort = arg => {
    if (this._isMounted) {
      this.setState({ currentSort: arg }, () => {
        this.fetchClients();
      });
    }
  };

  updateFilter = filter => {
    if (this._isMounted) {
      this.setState({ filter });
    }
  };

  fetchClients = () => {
    const { currentSort, filter, currentPage } = this.state;
    const { contactsStatus } = this.props;
    this.setState({ gonnaBeRefreshed: true }, () => {
      const ziFilter = {
        ...filter,
        installationDateFrom: filter.installationDateFrom
          ? moment(filter.installationDateFrom).format("YYYY-MM-DD")
          : "",
        installationDateTo: filter.installationDateTo ? moment(filter.installationDateTo).format("YYYY-MM-DD") : "",
        reminderDateFrom: filter.reminderDateFrom ? moment(filter.reminderDateFrom).format("YYYY-MM-DD") : "",
        reminderDateTo: filter.reminderDateTo ? moment(filter.reminderDateTo).format("YYYY-MM-DD") : ""
      };
      ClientService.getClients({
        page: currentPage,
        sort: currentSort ? currentSort.column : undefined,
        order: currentSort ? currentSort.order : undefined,
        filter: ziFilter,
        contactsStatus
      })
        .then(response => {
          if (this._isMounted) {
            this.setState({
              clients: response.contacts,
              totalCount: response.totalCount,
              gonnaBeRefreshed: false
            });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la suppression du client", { variant: "error" });
          if (this._isMounted) {
            this.setState({ gonnaBeRefreshed: false });
          }
        });
    });
  };

  handleClickOpen = () => {
    this.setState({ openFormModal: true, currentClient: null });
  };

  handleClickClose = ({ wRefresh = false }) => {
    if (wRefresh) {
      this.fetchClients();
    }
    this.setState({ openFormModal: false, currentClient: null });
  };

  toggleShowCreator = () => {
    const { hideCreator } = this.state;
    this.setState({ hideCreator: !hideCreator });
  };

  handleOpenExportMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseExportMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleExportAsCsv = () => {
    const { filter } = this.state;
    const ziFilter = {
      ...filter,
      installationDateFrom: filter.installationDateFrom ? moment(filter.installationDateFrom).format("YYYY-MM-DD") : "",
      installationDateTo: filter.installationDateTo ? moment(filter.installationDateTo).format("YYYY-MM-DD") : "",
      reminderDateFrom: filter.reminderDateFrom ? moment(filter.reminderDateFrom).format("YYYY-MM-DD") : "",
      reminderDateTo: filter.reminderDateTo ? moment(filter.reminderDateTo).format("YYYY-MM-DD") : ""
    };
    ClientService.exportClientsAsCsv({ filter: ziFilter })
      .then(response => {
        fileDownload(response, "export.csv");
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'export des clients", { variant: "error" });
      })
      .finally(() => {
        this.setState({ isExporting: false, anchorEl: null });
      });
  };

  render() {
    const { classes, history, contactsStatus, sessionStore } = this.props;
    const {
      currentClient,
      currentPage,
      currentSort,
      filter,
      gonnaBeRefreshed,
      clients,
      openFormModal,
      totalCount,
      menuOpenFor,
      hideCreator,
      isExporting,
      anchorEl
    } = this.state;

    const canExport = sessionStore.userHasAccess({
      requiredAcls: ["CONTACT_EXPORT"],
      requiredFeatures: ["CONTACT"]
    });

    return (
      <Fragment>
        <Grid container className={classes.container}>
          {contactsStatus === "ACTIVE" && (
            <Grid item xs={12} style={{ display: "flex" }}>
              <div className={classes.buttonsContainer}>
                <Hidden xsDown>
                  <Button variant="outlined" color="primary" onClick={() => this.handleClickOpen()}>
                    Nouveau client
                  </Button>
                </Hidden>
              </div>
            </Grid>
          )}
          <ClientsListFilter
            filter={filter}
            defaultFilter={this.defaultFilter}
            updateFilter={this.updateFilter}
            onSubmit={this.fetchClients}
          />
          {canExport && clients && clients.length > 0 && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                aria-controls="simple-export-menu"
                aria-haspopup="true"
                disabled={isExporting}
                variant="outlined"
                color="primary"
                style={{ marginTop: 24 }}
                onClick={this.handleOpenExportMenu}
              >
                {isExporting && <CircularProgress style={{ marginRight: 8 }} size={20} />} Exporter la liste
              </Button>
              <Menu
                id="simple-export-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleCloseExportMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <ListItem button onClick={this.handleExportAsCsv}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Format CSV"
                    secondary="Toutes les données principales des clients regroupées dans un tableau"
                  />
                </ListItem>
              </Menu>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="businessName"
                      >
                        Entreprise
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="lastname"
                      >
                        Nom
                      </TableSortableHeader>{" "}
                      /{" "}
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="firstname"
                      >
                        Prénom
                      </TableSortableHeader>
                    </TableCell>
                    <Hidden smDown>
                      <TableCell>Téléphone</TableCell>
                    </Hidden>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="zipCode"
                      >
                        Adresse
                      </TableSortableHeader>
                    </TableCell>
                    {!hideCreator && <TableCell>Créé par</TableCell>}
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="installationDate"
                      >
                        Date d&apos;installation
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="reminderDate"
                      >
                        Date de relance
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clients && gonnaBeRefreshed === false ? (
                    clients.length > 0 ? (
                      clients.map(client => (
                        <TableRow
                          key={client.id}
                          selected={client.id === menuOpenFor}
                          onClick={() => {
                            history.push(`/clients/${client.id}`);
                          }}
                          className={classes.row}
                        >
                          <TableCell>{client.businessName || "-"}</TableCell>
                          <TableCell>
                            {client.lastname} {client.firstname}
                          </TableCell>
                          <Hidden smDown>
                            <TableCell>
                              {client.phone}
                              {client.phone && client.phone2 ? <br /> : ""}
                              {client.phone2}
                            </TableCell>
                          </Hidden>
                          <TableCell>
                            <Hidden smDown>
                              {client.address}
                              <br />
                            </Hidden>
                            {client.zipCode} {client.city}
                          </TableCell>
                          {!hideCreator && (
                            <TableCell>
                              {client.user.firstname} {client.user.lastname}
                            </TableCell>
                          )}
                          <TableCell>
                            {client.installationDate ? moment(client.installationDate).format("DD/MM/YYYY") : "-"}
                          </TableCell>
                          <TableCell>
                            {client.reminderDate ? moment(client.reminderDate).format("DD/MM/YYYY") : "-"}
                          </TableCell>
                          <TableCell>{this.renderMenu(client)}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center" className={classes.loadingCell}>
                          {filter.q
                            ? "Aucun client ne correspond à la recherche"
                            : "Vous n'avez pas encore de client enregistré"}
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center" className={classes.loadingCell}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {clients && clients.length > 0 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
              )}
            </Paper>
            {this.canToggleShowCreator && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!hideCreator}
                      onChange={this.toggleShowCreator}
                      name="showCreator"
                      color="primary"
                    />
                  }
                  label="Afficher le propriétaire de chaque client"
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.handleClickOpen()}>
          <AddIcon />
        </Fab>
        <AddEditClientDialog open={openFormModal} currentClient={currentClient} handleClose={this.handleClickClose} />
      </Fragment>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(10)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  }
});

export default withRouter(withSnackbar(withStyles(styles)(ClientsList)));
