import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import {
  Button,
  CircularProgress,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import fileDownload from "js-file-download";
import { withSnackbar } from "notistack";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FolderIcon from "@material-ui/icons/Folder";

import InvoicesListFilter from "./InvoicesListFilter";
import InvoiceService from "../../services/InvoiceService";
import TableSortableHeader from "../table/TableSortableHeader";
import InvoiceStatusLabel from "./InvoiceStatusLabel";
import { formatCurrency } from "../../utils/numberFormat";
import { privateRoutes } from "../../routes";
import InvoiceStatusManager from "./InvoiceStatusManager";
import InvoicePaymentsManager from "./InvoicePaymentsManager";
import { INVOICE_STATUS } from "../../utils/status";
import PreviewInvoiceDialog from "../dialog/PreviewInvoiceDialog";
import InvoiceListActionsRow from "./InvoiceListActionsRow";
import LinkedAdditionalDocumentsDialog from "../dialog/LinkedAdditionalDocumentsDialog";

const styles = theme => ({
  container: { padding: theme.spacing(2) },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(10),
    overflowX: "auto"
  },
  table: { minWidth: 700 },
  rowDeleted: {
    background: theme.palette.action.disabled,
    color: theme.palette.text.disabled,
    textDecoration: "line-through"
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  buttonsContainer: {
    flex: 2,
    justifyContent: "flex-end",
    display: "flex"
  },
  rightAlign: { textAlign: "right" },
  centerAlign: { textAlign: "center" },
  rightMargin: { marginRight: theme.spacing() },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "rgb(224,224,244)"
  }
});

const ROWS_PER_PAGE = 50;
const defaultFilter = (view = "default") => ({
  q: "",
  userId: "",
  status: Object.keys(INVOICE_STATUS).filter(status => (view === "default" ? status !== "DRAFT" : status === "DRAFT")),
  from: null,
  to: null,
  client: ""
});

@inject("sessionStore")
@observer
class InvoicesList extends Component {
  constructor(props) {
    super(props);
    const { filter, sort, view } = props;
    this.state = {
      anchorEl: null,
      currentPage: 0,
      currentSort: sort || null,
      download: "",
      downloadHref: "",
      filter: { ...defaultFilter(view), ...(filter || {}) },
      gonnaBeRefreshed: false,
      invoices: null,
      openStatusManager: false,
      openPaymentsManager: false,
      selectedInvoice: null,
      totalCount: 0,
      menuOpenFor: null,
      isExporting: false,
      openPreview: false,
      openLinkedAdditionalDocumentsDialog: false
    };
  }

  componentDidMount() {
    this.fetchInvoices();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChangePage = (event, page) => {
    if (this._isMounted) {
      this.setState({ currentPage: page }, () => {
        this.fetchInvoices();
      });
    }
  };

  handleShowStatusManager = invoice => {
    this.setState({ openStatusManager: true, selectedInvoice: invoice });
  };

  handleHideStatusManager = () => {
    this.setState({ openStatusManager: false, selectedInvoice: null });
  };

  handleShowPaymentsManager = invoice => {
    this.setState({ openPaymentsManager: true, selectedInvoice: invoice });
  };

  handleHidePaymentsManager = () => {
    this.fetchInvoices();
    this.setState({ openPaymentsManager: false, selectedInvoice: null });
  };

  handleDeletion = invoice => {
    InvoiceService.deleteInvoice(invoice)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("La facture a été supprimée", { variant: "success" });
        this.fetchInvoices();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la suppression de la facture", { variant: "error" });
      });
  };

  duplicateInvoice = invoice => {
    InvoiceService.duplicateInvoice(invoice.id)
      .then(createdInvoice => {
        const { history } = this.props;
        history.push(privateRoutes.admin.invoices.editionForm.path.replace(":invoiceId", createdInvoice.id));
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la duplication de la facture", { variant: "error" });
      });
  };

  sendInvoice = invoice => {
    InvoiceService.sendInvoice(invoice)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("La facture a été envoyée", { variant: "success" });
        this.fetchInvoices();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'envoi de la facture", { variant: "error" });
      });
  };

  deleteOldDraft = invoice => {
    InvoiceService.validateInvoice(invoice)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        InvoiceService.intoCreditNote(invoice)
          .then(() => {
            enqueueSnackbar("La facture a été annulée par avoir", { variant: "success" });
            this.fetchInvoices();
          })
          .catch(() => {
            enqueueSnackbar(
              "Une erreur est survenue lors de l'annulation de la facture, cependant celle-ci est maintenant validée",
              { variant: "error" }
            );
            this.fetchInvoices();
          });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la validation de la facture", { variant: "error" });
      });
  };

  validateInvoice = invoice => {
    InvoiceService.validateInvoice(invoice)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("La facture a été validée", { variant: "success" });
        this.fetchInvoices();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la validation de la facture", { variant: "error" });
      });
  };

  downloadPDF = invoice => {
    InvoiceService.downloadPDF(invoice)
      .then(res => {
        const downloadHref = window.URL.createObjectURL(res);
        if (this._isMounted) {
          this.setState({ downloadHref, download: `Facture - ${invoice.number}.pdf` });
        }
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF de la facture", { variant: "error" });
      });
  };

  previewPDF = invoice => {
    this.setState({
      openPreview: true,
      selectedInvoice: invoice
    });
  };

  handleHidePreview = () => {
    this.setState({
      openPreview: false,
      selectedInvoice: null
    });
  };

  simulateClick = e => {
    // Avoid a blank page by checking if e is null or not.
    // Just remove the if condition to check the behavior.
    if (e !== null) {
      e.click();
    }
    this.setState({ downloadHref: "" });
  };

  transformIntoCreditNote = invoice => {
    InvoiceService.intoCreditNote(invoice)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("L'avoir associé à la facture a été créé", { variant: "success" });
        this.fetchInvoices();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'annulation de la facture", { variant: "error" });
      });
  };

  markInvoiceAs = ({ invoice, status }) =>
    InvoiceService.patchInvoice({ ...invoice, status })
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Le statut de la facture a été modifié", { variant: "success" });
        this.fetchInvoices();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de la facture", { variant: "error" });
      });

  addAdditionalDocument = (invoice, type) =>
    InvoiceService.addAdditionalDocument(invoice, type)
      .then(created => {
        const { enqueueSnackbar, history } = this.props;
        enqueueSnackbar("Le document a été créé", { variant: "success" });
        history.push(
          privateRoutes.common.additionalDocuments.worksAcceptanceDetails.path.replace(
            ":additionalDocumentId",
            created.id
          )
        );
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la création du document", { variant: "error" });
      });

  renderMenu = invoice => {
    const { sessionStore } = this.props;
    if (
      sessionStore.user.id === invoice.user.id ||
      sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_WRITE", "INVOICE_READ"],
        aclType: "oneof",
        requiredFeatures: ["INVOICE"]
      })
    )
      return (
        <InvoiceListActionsRow
          sessionStore={sessionStore}
          invoice={invoice}
          onOpen={() => this.setState({ menuOpenFor: invoice.id })}
          onClose={() => this.setState({ menuOpenFor: null })}
          onPreviewPDF={() => this.previewPDF(invoice)}
          onValidateInvoice={() => this.validateInvoice({ ...invoice })}
          onSendInvoice={() => this.sendInvoice({ ...invoice })}
          onMarkInvoiceAsSent={() => this.markInvoiceAs({ invoice, status: "SENT" })}
          onDownloadPDF={() => this.downloadPDF(invoice)}
          handleShowPaymentsManager={() => this.handleShowPaymentsManager(invoice)}
          handleDeletion={() => this.handleDeletion(invoice)}
          onDeleteOldDraft={() => this.deleteOldDraft(invoice)}
          transformIntoCreditNote={() => this.transformIntoCreditNote(invoice)}
          duplicateInvoice={() => this.duplicateInvoice(invoice)}
          addAdditionalDocument={type => this.addAdditionalDocument(invoice, type)}
          handleShowLinkedAdditionalDocumentsDialog={() => this.handleShowLinkedAdditionalDocumentsDialog(invoice)}
        />
      );
    return null;
  };

  handleSort = arg => {
    if (this._isMounted) {
      this.setState({ currentSort: arg }, () => {
        this.fetchInvoices();
      });
    }
  };

  updateFilter = async filter => {
    if (this._isMounted) {
      await this.setState({ filter });
    }
  };

  fetchInvoices = () => {
    const { currentSort, filter, currentPage } = this.state;
    const { view, sessionStore } = this.props;
    this.setState({ gonnaBeRefreshed: true }, () => {
      const ziFilter = {
        ...filter,
        userId: !sessionStore.userHasAccess({
          requiredAcls: ["INVOICE_READ"],
          requiredFeatures: ["INVOICE"]
        })
          ? sessionStore.user.id
          : filter.userId || "",
        status: filter.status.length === Object.keys(INVOICE_STATUS).length ? "" : filter.status.join(","),
        from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
        to: filter.to ? filter.to.format("YYYY-MM-DD") : "",
        rangeEvaluationAttr: view === "draft" ? "creation" : "validation"
      };
      InvoiceService.getInvoices({
        page: currentPage,
        sort: currentSort ? currentSort.column : view === "draft" ? "invoice.createAt" : "invoice.number",
        order: currentSort ? currentSort.order : "DESC",
        filter: ziFilter
      })
        .then(response => {
          if (this._isMounted) {
            this.setState({
              invoices: response.content,
              totalCount: response.totalCount,
              gonnaBeRefreshed: false
            });
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération des factures", { variant: "error" });
          if (this._isMounted) {
            this.setState({ gonnaBeRefreshed: false });
          }
        });
    });
  };

  handleOpenExportMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseExportMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleExportAsCsv = () => {
    const { filter } = this.state;
    const ziFilter = {
      ...filter,
      status: filter.status.length === Object.keys(INVOICE_STATUS).length ? "" : filter.status.join(","),
      from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
      to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
    };
    const { enqueueSnackbar } = this.props;
    this.setState({ isExporting: true, anchorEl: null }, () => {
      InvoiceService.exportInvoicesAsCsv({ filter: ziFilter })
        .then(response => {
          fileDownload(response, "export.csv");
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de l'export des factures", { variant: "error" });
        })
        .finally(() => {
          this.setState({ isExporting: false });
        });
    });
  };

  handleExportAsArchive = () => {
    const { filter } = this.state;
    const ziFilter = {
      ...filter,
      status: filter.status.length === Object.keys(INVOICE_STATUS).length ? "" : filter.status.join(","),
      from: filter.from ? filter.from.format("YYYY-MM-DD") : "",
      to: filter.to ? filter.to.format("YYYY-MM-DD") : ""
    };
    const { enqueueSnackbar } = this.props;
    if (ziFilter.status !== "DRAFT") {
      this.setState({ isExporting: true, anchorEl: null }, () => {
        InvoiceService.exportInvoices({ filter: ziFilter })
          .then(response => {
            if (response.byteLength > 0) {
              fileDownload(response, "factures.zip");
            } else {
              enqueueSnackbar("Aucune facture exportable sur la période");
            }
          })
          .catch(() => {
            enqueueSnackbar("Une erreur est survenue lors de l'export des factures", { variant: "error" });
          })
          .finally(() => {
            this.setState({ isExporting: false, anchorEl: null });
          });
      });
    } else {
      enqueueSnackbar("Aucune facture exportable sur la période");
    }
  };

  handleHideLinkedAdditionalDocumentsDialog = () => {
    this.setState({ openLinkedAdditionalDocumentsDialog: false, selectedInvoice: null });
  };

  handleShowLinkedAdditionalDocumentsDialog = invoice => {
    this.setState({ openLinkedAdditionalDocumentsDialog: true, selectedInvoice: invoice });
  };

  render() {
    const {
      classes,
      disableFilter = false,
      disableExport = false,
      sessionStore,
      hideDraft = false,
      hideStatusFilter = false,
      view = "default"
    } = this.props;
    const {
      anchorEl,
      currentPage,
      currentSort,
      download,
      downloadHref,
      filter,
      gonnaBeRefreshed,
      invoices,
      openPaymentsManager,
      openStatusManager,
      selectedInvoice,
      totalCount,
      menuOpenFor,
      isExporting,
      openPreview,
      openLinkedAdditionalDocumentsDialog
    } = this.state;

    const canExport = sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_EXPORT"],
      requiredFeatures: ["INVOICE"]
    });

    return (
      <Fragment>
        <Grid container className={classes.container}>
          {!disableFilter && (
            <InvoicesListFilter
              filter={filter}
              defaultFilter={defaultFilter(view)}
              updateFilter={this.updateFilter}
              onSubmit={this.fetchInvoices}
              hideDraft={hideDraft}
              hideStatusFilter={hideStatusFilter}
              rangeEvaluationAttr={view === "draft" ? "creation" : "validation"}
            />
          )}
          {!disableExport && canExport && invoices && invoices.length > 0 && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                aria-controls="simple-export-menu"
                aria-haspopup="true"
                disabled={isExporting}
                variant="outlined"
                color="primary"
                style={{ marginTop: 24 }}
                onClick={this.handleOpenExportMenu}
              >
                {isExporting && <CircularProgress style={{ marginRight: 8 }} size={20} />} Exporter la liste
              </Button>
              <Menu
                id="simple-export-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleCloseExportMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <ListItem button onClick={this.handleExportAsCsv}>
                  <ListItemIcon>
                    <ListAltIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Format CSV"
                    secondary="Toutes les données principales des factures regroupées dans un tableau"
                  />
                </ListItem>
                <ListItem button onClick={this.handleExportAsArchive}>
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Format ZIP"
                    secondary="Toutes les factures au format PDF dans une archive ZIP"
                  />
                </ListItem>
              </Menu>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper className={classes.root}>
              {invoices && invoices.length >= 15 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                  className={classes.borderBottom}
                />
              )}
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="invoice.createAt"
                      >
                        {view === "draft" ? "Créée le" : "Validée le"}
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell style={{ width: "20%" }}>
                      {/* {view === "default" && ( */}
                      <Fragment>
                        <TableSortableHeader
                          onClick={this.handleSort}
                          className={classes.sortableHeader}
                          currentSort={currentSort}
                          attribute="invoice.number"
                        >
                          Numéro
                        </TableSortableHeader>{" "}
                        /{" "}
                      </Fragment>
                      {/* )} */}
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="invoice.nature"
                      >
                        Objet
                      </TableSortableHeader>{" "}
                      /{" "}
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="user.lastname"
                      >
                        Utilisateur
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="contact.lastname"
                      >
                        Client
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell className={classes.centerAlign}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="invoice.totalPriceWithoutTVA"
                      >
                        Montant HT
                      </TableSortableHeader>
                    </TableCell>
                    <TableCell className={classes.centerAlign}>Montant TVA</TableCell>
                    <TableCell className={classes.centerAlign}>
                      <TableSortableHeader
                        onClick={this.handleSort}
                        className={classes.sortableHeader}
                        currentSort={currentSort}
                        attribute="invoice.totalPriceWithTVA"
                      >
                        Montant TTC
                      </TableSortableHeader>
                    </TableCell>
                    {view === "default" && <TableCell>Statut</TableCell>}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices && invoices.length >= 0 && gonnaBeRefreshed === false ? (
                    invoices.map(invoice => (
                      <TableRow
                        className={invoice.deleted ? classes.rowDeleted : null}
                        key={invoice.id}
                        selected={invoice.id === menuOpenFor}
                      >
                        <TableCell>
                          {view === "draft"
                            ? moment(invoice.createAt).format("DD/MM/YYYY")
                            : moment(invoice.validationDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell style={{ width: "20%" }}>
                          {/* {view === "default" && ( */}
                          <Fragment>
                            N°{invoice.number}
                            <br />
                          </Fragment>
                          {/* )} */}
                          <span style={{ fontWeight: "bold" }}>{invoice.nature}</span>
                          {invoice.user && (
                            <Fragment>
                              <br />
                              par {invoice.user.firstname} {invoice.user.lastname.toUpperCase()}
                            </Fragment>
                          )}
                          {invoice.additionalDocuments && invoice.additionalDocuments.length > 0 && (
                            <Fragment>
                              <br />
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                onClick={() => {
                                  this.handleShowLinkedAdditionalDocumentsDialog(invoice);
                                }}
                              >
                                + {invoice.additionalDocuments.length} document
                                {invoice.additionalDocuments.length > 1 && "s"} lié
                                {invoice.additionalDocuments.length > 1 && "s"}
                              </Typography>
                            </Fragment>
                          )}
                        </TableCell>
                        {invoice.contact ? (
                          <TableCell>
                            {invoice.contact.businessName ? (
                              <Fragment>
                                {invoice.contact.businessName}
                                <br />({invoice.contact.firstname} {invoice.contact.lastname.toUpperCase()})
                              </Fragment>
                            ) : (
                              <Fragment>
                                {invoice.contact.firstname} {invoice.contact.lastname.toUpperCase()}
                              </Fragment>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {invoice.clientBusinessName}
                            {invoice.clientBusinessName && <br />}
                            {`${invoice.clientBusinessName && "("}${
                              invoice.clientFirstname
                            } ${invoice.clientLastname.toUpperCase()}${invoice.clientBusinessName && ")"}`}
                          </TableCell>
                        )}

                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(invoice.totalPriceWithoutTVA, invoice.currency)}
                        </TableCell>
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(invoice.totalPriceWithTVA - invoice.totalPriceWithoutTVA, invoice.currency)}
                        </TableCell>
                        <TableCell className={classes.rightAlign}>
                          {formatCurrency(invoice.totalPriceWithTVA, invoice.currency)}
                        </TableCell>
                        {view === "default" && (
                          <TableCell component="th" scope="row">
                            <InvoiceStatusLabel
                              canBeManaged={
                                sessionStore.user.id === invoice.user.id ||
                                sessionStore.userHasAccess({
                                  requiredAcls: ["INVOICE_MY"],
                                  requiredFeatures: ["INVOICE"]
                                })
                              }
                              invoice={invoice}
                              handleChangeStatus={
                                invoice.status !== "DRAFT"
                                  ? () => this.handleShowStatusManager(invoice)
                                  : () => this.markInvoiceAs({ invoice, status: "VALIDATED" })
                              }
                            />
                          </TableCell>
                        )}
                        <TableCell>{this.renderMenu(invoice)}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center" className={classes.loadingCell}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {invoices && invoices.length >= 0 && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  rowsPerPage={ROWS_PER_PAGE}
                  rowsPerPageOptions={[ROWS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
              )}
            </Paper>
          </Grid>
          {downloadHref !== "" && (
            <a ref={this.simulateClick} href={downloadHref} download={download} style={{ display: "none" }}>
              Download
            </a>
          )}
        </Grid>

        <InvoiceStatusManager
          onClose={this.handleHideStatusManager}
          handleSubmit={this.markInvoiceAs}
          open={openStatusManager}
          invoice={selectedInvoice}
        />

        <InvoicePaymentsManager
          onClose={this.handleHidePaymentsManager}
          handleSubmit={this.markInvoiceAs}
          open={openPaymentsManager}
          invoice={selectedInvoice}
        />

        <PreviewInvoiceDialog onClose={this.handleHidePreview} open={openPreview} invoice={selectedInvoice} />

        <LinkedAdditionalDocumentsDialog
          onClose={this.handleHideLinkedAdditionalDocumentsDialog}
          open={openLinkedAdditionalDocumentsDialog}
          invoice={selectedInvoice}
        />
      </Fragment>
    );
  }
}

export default withSnackbar(withRouter(withStyles(styles)(InvoicesList)));
