import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MomentUtils from "@date-io/moment";
import React, { useEffect, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  FormControl,
  InputLabel,
  Grid,
  Button,
  TextField,
  MenuItem,
  Badge,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  IconButton,
  Avatar,
  Chip,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { inject } from "mobx-react";

import { INVOICE_STATUS } from "../../utils/status";
import InvoiceStatusLabel from "./InvoiceStatusLabel";
import KKIcons from "../KKIcons";
import LinkClientDialog from "../dialog/LinkClientDialog";
import ClientService from "../../services/ClientService";
import FilterUserInput from "../FilterUserInput";

const MenuProps = {
  PaperProps: { style: { width: 250 } }
};

let timeoutSearch;

const useStyles = makeStyles(theme => ({
  woMarginTop: { marginTop: 0 },
  advancedContainer: { marginTop: theme.spacing(3) },
  advancedFieldsContainer: {
    paddingBottom: theme.spacing(),
    backgroundColor: "#FCFCFC"
  },
  advancedActionContainer: { backgroundColor: "#FCFCFC" },
  advancedInput: { marginTop: theme.spacing(2) },
  advancedInputContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  expWrapper: { overflow: "hidden" },
  chip: { margin: 2 },
  chipContainer: { display: "flex", flexWrap: "wrap" }
}));

const defaultStatusFilterIsCurrentFilter = (defaultStatus, selectedStatus) =>
  defaultStatus.length === selectedStatus.length && defaultStatus.sort().join(",") === selectedStatus.sort().join(",");

function InvoicesListFilter({
  sessionStore,
  filter,
  updateFilter,
  onSubmit,
  defaultFilter,
  hideDraft = false,
  hideStatusFilter = false,
  rangeEvaluationAttr = "creation" // creation | validation
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [showClientPicker, setShowClientPicker] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let _activeFiltersCount = 0;
    if (filter.from) {
      _activeFiltersCount += 1;
    }
    if (filter.to) {
      _activeFiltersCount += 1;
    }
    if (
      filter.status.length > 0 &&
      !hideStatusFilter &&
      !defaultStatusFilterIsCurrentFilter(defaultFilter.status || [], filter.status)
    ) {
      _activeFiltersCount += 1;
    }
    if (filter.userId) {
      _activeFiltersCount += 1;
    }
    setActiveFiltersCount(_activeFiltersCount);
  }, [filter]);

  const handleChange = event => {
    updateFilter({ ...filter, status: event.target.value });
  };

  const handleQueryChange = e => {
    updateFilter({ ...filter, q: e.target.value });
    clearTimeout(timeoutSearch);
    timeoutSearch = setTimeout(() => {
      onSubmit();
    }, 500);
  };

  const handleFromDateChange = date => {
    updateFilter({ ...filter, from: date });
  };

  const handleToDateChange = date => {
    updateFilter({ ...filter, to: date });
  };

  const handleExpansion = (e, expanded) => {
    setIsExpanded(expanded);
  };

  const handleSearch = () => {
    setIsExpanded(false);
    onSubmit();
  };

  const handleUserChange = event => {
    updateFilter({ ...filter, userId: event.target.value });
  };

  const handleResetAdvanced = async () => {
    updateFilter(defaultFilter);
    setSelectedClient(null);
  };

  const handleSearchClient = e => {
    e.stopPropagation();
    setShowClientPicker(true);
  };

  const handleClient = async client => {
    if (client && client.id !== filter.client) {
      setSelectedClient(client);
      await updateFilter({ ...filter, client: client.id });
      onSubmit();
    }
    setShowClientPicker(false);
  };

  const handleClickDeleteClient = async () => {
    setSelectedClient(null);
    await updateFilter({ ...filter, client: "" });
    onSubmit();
  };

  useEffect(() => {
    if (filter.client) {
      ClientService.getClient(filter.client)
        .then(res => {
          setSelectedClient(res.contact);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la sélection du client", { variant: "error" });
        });
    }
  }, []);

  return (
    <Grid item xs={12} className={classes.advancedContainer}>
      <ExpansionPanel onChange={handleExpansion} expanded={isExpanded} className={classes.expWrapper}>
        <ExpansionPanelSummary
          expandIcon={
            !isExpanded && activeFiltersCount > 0 ? (
              <Badge badgeContent={activeFiltersCount} color="primary">
                <ExpandMoreIcon />
              </Badge>
            ) : (
              <ExpandMoreIcon />
            )
          }
        >
          <TextField
            className={classes.woMarginTop}
            onClick={e => e.stopPropagation()}
            name="q"
            placeholder={matches ? "Recherche" : "Numéro de facture, objet, nom du client"}
            label={matches ? "Recherche" : "Numéro de facture, objet, nom du client"}
            margin="normal"
            fullWidth
            value={filter.q}
            onChange={handleQueryChange}
          />
          <div>
            {selectedClient ? (
              <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                <Chip
                  style={{ maxWidth: "25vw" }}
                  avatar={<Avatar>{selectedClient.lastname[0]}</Avatar>}
                  label={`${selectedClient.lastname.toUpperCase()} ${selectedClient.firstname}`}
                  onClick={handleSearchClient}
                  variant="outlined"
                  onDelete={handleClickDeleteClient}
                />
              </div>
            ) : (
              <IconButton aria-label="search" onClick={handleSearchClient} style={{ width: 56, height: 56 }}>
                <KKIcons.SearchUserIcon />
              </IconButton>
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.advancedFieldsContainer}>
          <Grid container>
            {!hideStatusFilter && (
              <Grid item xs={12} className={classes.advancedInputContainer}>
                <FormControl fullWidth variant="outlined" className={classes.advancedInput}>
                  <InputLabel>Statut(s)</InputLabel>
                  <Select
                    id="invoice-statuses"
                    multiple
                    value={filter.status}
                    onChange={handleChange}
                    input={<OutlinedInput labelWidth={63} />}
                    renderValue={selected => (
                      <div className={classes.chipContainer}>
                        {selected.map(value => (
                          <div key={value} className={classes.chip}>
                            <InvoiceStatusLabel invoiceStatus={value} />
                          </div>
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {Object.keys(INVOICE_STATUS)
                      .filter(statusKey => hideDraft && statusKey !== "DRAFT")
                      .map(statusKey => (
                        <MenuItem key={statusKey} value={statusKey}>
                          <Checkbox checked={filter.status.includes(statusKey)} color="primary" />
                          <ListItemText primary={INVOICE_STATUS[statusKey].label} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                <DatePicker
                  className={classes.advancedInput}
                  id="from"
                  cancelLabel="Annuler"
                  clearLabel="Réinitialiser"
                  clearable
                  format="D MMM YYYY"
                  fullWidth
                  label={rangeEvaluationAttr === "creation" ? "Créée après le" : "Validée après le"}
                  leftArrowIcon={<KeyboardArrowLeftIcon />}
                  rightArrowIcon={<KeyboardArrowRightIcon />}
                  maxDate={filter.to ? filter.to : undefined}
                  value={filter.from}
                  onChange={handleFromDateChange}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} className={classes.advancedInputContainer}>
              <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                <DatePicker
                  className={classes.advancedInput}
                  id="to"
                  cancelLabel="Annuler"
                  format="D MMM YYYY"
                  fullWidth
                  clearable
                  clearLabel="Réinitialiser"
                  label={rangeEvaluationAttr === "creation" ? "Créée avant le" : "Validée avant le"}
                  leftArrowIcon={<KeyboardArrowLeftIcon />}
                  rightArrowIcon={<KeyboardArrowRightIcon />}
                  minDate={filter.from ? filter.from : undefined}
                  value={filter.to}
                  onChange={handleToDateChange}
                  inputVariant="outlined"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {sessionStore.userHasAccess({
              requiredAcls: ["INVOICE_READ"],
              requiredFeatures: ["INVOICE"]
            }) && (
              <Grid item xs={12} lg={6} className={classes.advancedInputContainer}>
                <div className={classes.advancedInput}>
                  <FilterUserInput filter={filter} onChange={handleUserChange} />
                </div>
              </Grid>
            )}
          </Grid>
        </ExpansionPanelDetails>
        <ExpansionPanelActions className={classes.advancedActionContainer}>
          <Button size="small" color="primary" onClick={handleResetAdvanced}>
            Réinitialiser
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={handleSearch}>
            Filtrer
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
      {showClientPicker && <LinkClientDialog handleClose={handleClient} client={null} disableCreation />}
    </Grid>
  );
}

export default inject("sessionStore")(InvoicesListFilter);
