import request, { METHOD } from "../utils/request";

class YouSignService {
  static getDownloadOrderPDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadOrder`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getDownloadEstimatePDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadEstimate`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getDownloadDocSignaturePDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadDocSignature`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getDownloadAdditionalDocumentPDF(signatureRequestId) {
    return request({
      url: `/yousign/${signatureRequestId}/downloadAdditionalDocument`,
      method: METHOD.GET,
      responseType: "blob"
    });
  }

  static getSignatureUrl(companyId, signatureRequestId, contentId, contentType, signatoryId) {
    return request({
      url: `/yousign/${signatoryId}/signatureUrl`,
      method: METHOD.POST,
      data: {
        companyId,
        signatureRequestId,
        contentType,
        contentId
      }
    });
  }
}

export default YouSignService;

export const yousignDefaultSignatureType = session => {
  const { user } = session;
  if (user.company && user.company.canYousignClassic) return "no_otp";
  if (user.company && user.company.canYousignEmailOTP) return "otp_email";
  if (user.company && user.company.canYousignPhoneOTP) return "otp_sms";
  return "no_otp";
};

export const canYousignClassic = session => {
  const { user } = session;
  return user.company && user.company.canYousignClassic;
};

export const canYousignEmailOTP = session => {
  const { user } = session;
  return user.company && user.company.canYousignEmailOTP;
};

export const canYousignPhoneOTP = session => {
  const { user } = session;
  return user.company && user.company.canYousignPhoneOTP;
};
