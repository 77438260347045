import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  withStyles,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  DialogContent,
  Button,
  Badge,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Grid,
  Checkbox
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { isValidPhoneNumber } from "libphonenumber-js";
import DoneIcon from "@material-ui/icons/Done";
import ReplayIcon from "@material-ui/icons/Replay";
import { useSnackbar } from "notistack";
import { inject } from "mobx-react";

import YouSignSignerFrame from "./YouSignSignerFrame";
import YouSignService, {
  canYousignClassic,
  canYousignEmailOTP,
  canYousignPhoneOTP,
  yousignDefaultSignatureType
} from "../../services/YouSignService";
import YousignInstructionsCard from "../signature/YousignInstructionsCard";
import AdditionalDocumentService from "../../services/AdditionalDocumentService";

const styles = theme => ({
  appBar: { position: "relative" },
  flex: { flex: 1 },
  phoneDetails: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    opacity: 0.8
  }
});

function AdditionalDocumentYousignDialog({ open, handleClose, classes, doc, currentUser, sessionStore }) {
  const [isOpen, setIsOpen] = useState(false);
  const [additionalDocument, setAdditionalDocument] = useState({ ...doc });
  const [includeCurrentUserAsSigner, setIncludeCurrentUserAsSigner] = useState(
    additionalDocument.type === "WORKS_ACCEPTANCE"
  );

  const [downloadHref, setDownloadHref] = useState(undefined);
  const [selectedSigner, setSelectedSigner] = useState(undefined);
  const [clientSignatureType, setClientSignatureType] = useState(yousignDefaultSignatureType(sessionStore));
  const [clientPhone, setClientPhone] = useState(additionalDocument.contact.phone || "");
  const [clientEmail, setClientEmail] = useState(additionalDocument.contact.email || "");
  const [currentUserSignatureType, setCurrentUserSignatureType] = useState(yousignDefaultSignatureType(sessionStore));
  const [isProcessing, setIsProcessing] = useState(false);
  const [showSentSuccess, setShowSentSuccess] = useState(false);
  const [sendInvitations, setSendInvitations] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = event => {
    setClientSignatureType(event.target.value);
  };

  const handleCurrentUserSignatureTypeChange = event => {
    setCurrentUserSignatureType(event.target.value);
  };

  const handleClientEmailChange = e => {
    setClientEmail(e.target.value);
  };

  const handleClientPhoneChange = e => {
    setClientPhone(e.target.value);
  };

  const simulateClick = e => {
    if (e !== null) {
      e.click();
    }
    setDownloadHref(undefined);
  };

  const initSignature = () => {
    setIsProcessing(true);
    AdditionalDocumentService.sendAdditionalDocumentForSignature(additionalDocument, {
      includeCurrentUserAsSigner: true,
      client: { ...additionalDocument.contact, phone: clientPhone, email: clientEmail },
      clientSignatureType,
      currentUserSignatureType,
      sendInvitations
    })
      .then(res => {
        if (sendInvitations) {
          setShowSentSuccess(true);
        }
        setAdditionalDocument(res);
        setIsProcessing(false);
      })
      .catch(() => {
        setIsProcessing(false);
        enqueueSnackbar("Une erreur est survenue lors de l'initialisation de la signature", { variant: "error" });
      });
  };

  const queryClient = useQueryClient();

  const { isLoading, data, refetch, isFetching } = useQuery({
    queryKey: ["signature", { id: additionalDocument.signature ? additionalDocument.signature.signatureId : "NONE" }],
    queryFn: () => AdditionalDocumentService.getAdditionalDocumentSignature(additionalDocument.id).then(res => res),
    enabled:
      !!additionalDocument.id &&
      !!additionalDocument.signature &&
      additionalDocument.signature.id &&
      !selectedSigner &&
      !showSentSuccess,
    refetchInterval: _data => (_data && _data.signatureRequest.status !== "done" ? 20000 : false),
    cacheTime: 15000,
    staleTime: 15000
  });

  const handleManualClose = () => {
    handleClose({
      needRefresh:
        (!doc.signatureRequestId && additionalDocument.signatureRequestId) || (data && data.signatureRequest.status)
    });
  };

  const remainingSignersCount = data ? data.signers.filter(signer => signer.status !== "signed").length : undefined;

  const handleDownload = () => {
    YouSignService.getDownloadAdditionalDocumentPDF(data.signatureRequest.id)
      .then(res => {
        setDownloadHref(window.URL.createObjectURL(res));
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors du téléchargement du PDF du bon de commande", {
          variant: "error"
        });
      });
  };

  useEffect(() => {
    setSelectedSigner(undefined);
    setIsOpen(open);
    setAdditionalDocument({ ...doc });
  }, [open]);

  const isClientNumberValid = useMemo(() => isValidPhoneNumber(clientPhone, "FR"), [clientPhone]);

  return (
    <Dialog fullScreen open={isOpen} onClose={handleManualClose}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleManualClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Signature
          </Typography>
        </Toolbar>
      </AppBar>
      {open && !additionalDocument.signature && !showSentSuccess && !data && (
        <DialogContent style={{ display: "flex", flexDirection: "column", paddingTop: 20 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={4}>
              <Card style={{ height: "100%" }}>
                <CardHeader
                  title={`Signataire #1 - ${additionalDocument.contact.firstname} ${additionalDocument.contact.lastname}`}
                />
                <CardContent>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleClientEmailChange}
                    placeholder="Email"
                    value={clientEmail}
                    variant="outlined"
                    style={{ marginBottom: 24 }}
                  />
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Niveau de signature</FormLabel>
                    <RadioGroup
                      aria-label="clientSignatureType"
                      name="clientSignatureType"
                      value={clientSignatureType}
                      onChange={handleChange}
                    >
                      {canYousignClassic(sessionStore) && (
                        <FormControlLabel value="no_otp" control={<Radio color="primary" />} label="Manuscrite" />
                      )}
                      {canYousignEmailOTP(sessionStore) && (
                        <FormControlLabel
                          value="otp_email"
                          control={<Radio color="primary" />}
                          label="Avec validation par code email"
                        />
                      )}
                      {canYousignPhoneOTP(sessionStore) && (
                        <FormControlLabel
                          value="otp_sms"
                          control={<Radio color="primary" />}
                          label="Avec validation par code SMS"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                  {clientSignatureType === "otp_sms" && (
                    <div>
                      <Typography variant="caption" style={{ opacity: 0.8 }}>
                        Afin d&apos;envoyer pour signature le document, veuillez confirmer le numéro de téléphone de
                        votre client.
                      </Typography>
                      <TextField
                        autoFocus
                        fullWidth
                        label="Téléphone mobile"
                        name="phone"
                        onChange={handleClientPhoneChange}
                        placeholder="Téléphone mobile"
                        value={clientPhone}
                        variant="outlined"
                        style={{ marginTop: 16 }}
                        error={!isClientNumberValid}
                        helperText={!isClientNumberValid && "Le numéro renseigné n'est pas valide"}
                      />
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {additionalDocument.type !== "VAT_DECLARATION" && (
              <Grid item xs={12} md={4}>
                <Card style={{ height: "100%" }}>
                  <CardHeader title={`Signataire #2 - ${currentUser.firstname} ${currentUser.lastname} (Vous)`} />
                  <CardContent style={{ display: "flex", flexDirection: "column", gap: 16 }}>
                    {additionalDocument.type !== "WORKS_ACCEPTANCE" && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeCurrentUserAsSigner}
                            name="includeCurrentUserAsSigner"
                            color="primary"
                            onChange={e => setIncludeCurrentUserAsSigner(e.target.checked)}
                          />
                        }
                        label="Je souhaite signer ce document"
                      />
                    )}
                    {includeCurrentUserAsSigner && (
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Niveau de signature</FormLabel>
                        <RadioGroup
                          aria-label="clientSignatureType"
                          name="currentUserSignatureType"
                          value={currentUserSignatureType}
                          onChange={handleCurrentUserSignatureTypeChange}
                        >
                          {canYousignClassic(sessionStore) && (
                            <FormControlLabel value="no_otp" control={<Radio color="primary" />} label="Manuscrite" />
                          )}
                          {canYousignEmailOTP(sessionStore) && (
                            <FormControlLabel
                              value="otp_email"
                              control={<Radio color="primary" />}
                              label="Avec validation par code email"
                            />
                          )}
                          {canYousignPhoneOTP(sessionStore) && (
                            <FormControlLabel
                              disabled={!currentUser.phone}
                              value="otp_sms"
                              control={<Radio color="primary" />}
                              label={
                                currentUser.phone
                                  ? `Avec validation par code SMS - ${currentUser.phone}`
                                  : "Vous devez renseigner votre numéro de téléphone dans votre profil"
                              }
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                paddingTop: 12,
                flexDirection: "column",
                gap: 20,
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendInvitations}
                    name="sendInvitations"
                    color="primary"
                    onChange={e => setSendInvitations(e.target.checked)}
                  />
                }
                label="Envoyer l'invitation par email"
              />
              <Button
                onClick={initSignature}
                variant="contained"
                color="primary"
                style={{ width: 250 }}
                disabled={isProcessing || (!isClientNumberValid && clientSignatureType === "otp_sms")}
              >
                Lancer la signature
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {open && showSentSuccess && (
        <DialogContent
          style={{ display: "flex", flexDirection: "column", paddingTop: 40, alignItems: "center", gap: 20 }}
        >
          <DoneIcon fontSize="large" style={{ fontSize: 50 }} />
          <Typography variant="h4" className={classes.successTitle}>
            Félicitations
          </Typography>
          <Typography variant="body1">Vous venez d&apos;envoyer votre document pour signature !</Typography>
          <Button variant="contained" color="primary" onClick={handleManualClose}>
            Fermer
          </Button>
        </DialogContent>
      )}
      {open && !showSentSuccess && data && data.signers && (
        <DialogContent style={{ display: "flex", flexDirection: "column", backgroundColor: "#F7F7F7" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: 16,
              marginBottom: 40
            }}
          >
            {data &&
              data.signers.map(signer => (
                <Badge invisible={signer.status === "signed"} variant="dot" color="error" key={signer.id}>
                  <Button
                    disabled={signer.status === "signed" || data.signatureRequest.status === "expired"}
                    onClick={() => {
                      if (selectedSigner && signer.id === selectedSigner.id) {
                        setSelectedSigner(undefined);
                      } else {
                        setSelectedSigner(signer);
                      }
                    }}
                    color="primary"
                    variant={selectedSigner && selectedSigner.id === signer.id ? "contained" : "outlined"}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    {signer.info.first_name} {signer.info.last_name}{" "}
                    {signer.status === "signed" && <VerifiedUserIcon color="primary" style={{ marginLeft: 10 }} />}
                  </Button>
                </Badge>
              ))}
          </div>
          <div
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            {isLoading && <div>Chargement...</div>}
            {data && data.signatureRequest && !selectedSigner && (
              <div style={{ textAlign: "center" }}>
                {data.signatureRequest.status === "done" ? (
                  <Fragment>
                    <p>Félicitations, le document est signé !</p>
                    <Button variant="contained" color="primary" onClick={handleDownload}>
                      Télécharger
                    </Button>
                  </Fragment>
                ) : remainingSignersCount === 0 ? (
                  <p>Finalisation de la signature...</p>
                ) : (
                  <Fragment>
                    <Button
                      startIcon={<ReplayIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => refetch()}
                      disabled={isFetching}
                    >
                      Rafraichir
                    </Button>
                    {data.signatureRequest.status === "expired" ? (
                      <p style={{ fontStyle: "italic", marginBottom: 32 }}>
                        Le délai alloué à la signature est dépassé
                      </p>
                    ) : (
                      <p style={{ fontStyle: "italic", marginBottom: 32 }}>
                        Il manque {remainingSignersCount} signature{remainingSignersCount > 1 && "s"}
                      </p>
                    )}
                    <YousignInstructionsCard />
                  </Fragment>
                )}
              </div>
            )}
            {!isLoading && selectedSigner !== undefined && (
              <YouSignSignerFrame
                signatureUrl={selectedSigner.signature_link}
                onSign={() => {
                  setSelectedSigner(undefined);
                  queryClient.invalidateQueries(["signature", { id: additionalDocument.signatureRequestId }]);
                }}
              />
            )}
          </div>
          {downloadHref !== "" && (
            <a ref={simulateClick} href={downloadHref} download="Document.pdf" style={{ display: "none" }}>
              Download
            </a>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}

export default inject("sessionStore")(withStyles(styles)(AdditionalDocumentYousignDialog));
