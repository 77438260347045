import React, { Component } from "react";
import { withStyles, AppBar, Dialog, IconButton, Toolbar, Typography, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import { withSnackbar } from "notistack";

import AddSellerForm from "../form/AddSellerForm";
import { TransitionUp } from "../utils/transitions/TransitionUp";
import CompanyService from "../../services/CompanyService";

const styles = {
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
};

const emptySeller = {
  firstname: "",
  lastname: "",
  email: "",
  job: "",
  phone: "",
  company: ""
};

class EditSellerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ open: props.open, loading: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleSubmit = seller => {
    this.setState({ loading: true }, () => {
      const promise = seller && seller.id ? CompanyService.updateUser(seller) : CompanyService.addUser(seller);
      promise
        .then(res => {
          const { onAddOrUpdate } = this.props;
          onAddOrUpdate(res);
          this.setState({ open: false });
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("L'utilisateur a été modifié", { variant: "success" });
        })
        .catch(error => {
          if (error.status === 409) {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar("L'utilisateur existe déjà", { variant: "info" });
          } else {
            const { enqueueSnackbar } = this.props;
            enqueueSnackbar(
              error.status === 400
                ? "Les informations de l'utilisateur sont incorrectes"
                : "Une erreur est survenue lors de la mise à jour de l'utilisateur",
              {
                variant: "error"
              }
            );
          }
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { classes, seller } = this.props;
    const { open, loading } = this.state;

    return (
      <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={TransitionUp}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Modifier un utilisateur
            </Typography>
          </Toolbar>
        </AppBar>
        {open && (
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <AddSellerForm
                handleSubmit={this.handleSubmit}
                handleCancel={this.handleClose}
                isSubmitting={loading}
                seller={seller || emptySeller}
              />
            </Grid>
          </Grid>
        )}
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(EditSellerDialog));
