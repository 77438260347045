import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";
import { isValidPhoneNumber } from "libphonenumber-js";

import LinkClientDialog from "../dialog/LinkClientDialog";
import SignatureSignatory from "./SignatureSignatory";
import ValidatorService from "../../services/ValidatorService";
import {
  canYousignClassic,
  canYousignEmailOTP,
  canYousignPhoneOTP,
  yousignDefaultSignatureType
} from "../../services/YouSignService";

const useStyles = makeStyles(theme => ({
  paperWithSpacing: {
    padding: theme.spacing(2)
  },
  centerContentWDensePadding: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(3)
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
  signatories: { marginTop: theme.spacing(3), marginBottom: theme.spacing() },
  inputWithTooltip: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  }
}));

function SignatureSignatories({
  signatories,
  updateSignatories,
  sessionStore,
  includeUser,
  toggleIncludeUser,
  userSignatureType,
  setUserSignatureType,
  sendInvitations,
  toggleSendInvitations
}) {
  const [showPickContact, setShowPickContact] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const classes = useStyles();

  const providerIsYousign = sessionStore.user.company.signatureProvider === "YOUSIGN";
  const currentUserPhone = sessionStore.user.phone;

  const handleAddSignatory = () => {
    setSelectedIndex(null);
    setSelectedClient(null);
    setShowPickContact(true);
  };

  const handleCloseClientDialog = res => {
    if (res) {
      if (selectedIndex !== null) {
        updateSignatories(_signatories =>
          _signatories.map((item, index) =>
            index === selectedIndex
              ? {
                  ...res,
                  type: res.businessName ? "morale" : "physique",
                  signatureType: item.signatureType ? item.signatureType : yousignDefaultSignatureType(sessionStore)
                }
              : item
          )
        );
      } else {
        updateSignatories(_signatories => [
          ..._signatories,
          {
            ...res,
            type: res.businessName ? "morale" : "physique",
            signatureType: yousignDefaultSignatureType(sessionStore)
          }
        ]);
      }
    }
    setShowPickContact(false);
    setSelectedClient(null);
    setSelectedIndex(null);
  };

  const handleUpdateContact = updatedContact => {
    updateSignatories(_signatories =>
      _signatories.map(item => (item.id === updatedContact.id ? { ...updatedContact } : item))
    );
  };

  const handleItemClick = (contact, index) => {
    setSelectedIndex(index);
    setSelectedClient(contact);
    setShowPickContact(true);
  };

  const handleDelete = contactIndex => {
    updateSignatories(_signatories => _signatories.filter((item, index) => index !== contactIndex));
  };

  const handleChange = event => {
    setUserSignatureType(event.target.value);
  };

  const userPhoneIsValid = useMemo(() => {
    if (!currentUserPhone) return false;
    if (providerIsYousign) {
      return isValidPhoneNumber(currentUserPhone, "FR");
    }
    return ValidatorService.validateMobilePhone(currentUserPhone);
  }, [sessionStore]);

  const disabledClientId = useMemo(() => signatories.map(signatory => signatory.id || null), [signatories]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paperWithSpacing}>
          <Typography variant="body1">Vous pouvez renseigner jusqu&apos;à 5 signataires</Typography>
          {signatories.length > 0 ? (
            <Grid container className={classes.signatories} spacing={2}>
              {signatories.map((signatory, index) => (
                <Grid item xs={12} key={`signatory-${index + 1}`} md={6}>
                  <SignatureSignatory
                    contact={signatory}
                    key={`signatory-${index + 1}`}
                    onClick={handleItemClick}
                    onDelete={handleDelete}
                    index={index}
                    updateContact={handleUpdateContact}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box className={classes.centerContentWDensePadding}>
              <Typography variant="caption">Renseignez un premier signataire pour continuer</Typography>
            </Box>
          )}
          {signatories.length < 5 && (
            <Box className={classes.centerContent}>
              <Button color="primary" variant="contained" onClick={handleAddSignatory}>
                Ajouter un signataire
              </Button>
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperWithSpacing}>
          <Box className={classes.inputWithTooltip}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={includeUser} onChange={toggleIncludeUser} name="checkedB" color="primary" />
                }
                label="Je signerai également ce document"
              />
              {!providerIsYousign && (
                <FormHelperText>
                  {userPhoneIsValid
                    ? `Signature SMS possible : ${currentUserPhone}`
                    : "Non éligible à la signature SMS"}
                  . Pour modifier cette valeur rendez-vous dans votre profil
                </FormHelperText>
              )}
            </FormGroup>
            {providerIsYousign && includeUser && (
              <FormControl component="fieldset" style={{ marginTop: 16 }}>
                <FormLabel component="legend">Niveau de signature</FormLabel>
                <RadioGroup
                  aria-label="clientSignatureType"
                  name="currentUserSignatureType"
                  value={userSignatureType}
                  onChange={handleChange}
                >
                  {canYousignClassic(sessionStore) && (
                    <FormControlLabel value="no_otp" control={<Radio color="primary" />} label="Manuscrite" />
                  )}
                  {canYousignEmailOTP(sessionStore) && (
                    <FormControlLabel
                      value="otp_email"
                      control={<Radio color="primary" />}
                      label="Avec validation par code email"
                    />
                  )}
                  {canYousignPhoneOTP(sessionStore) && (
                    <FormControlLabel
                      disabled={!currentUserPhone}
                      value="otp_sms"
                      control={<Radio color="primary" />}
                      label={
                        currentUserPhone
                          ? `Avec validation par code SMS - ${currentUserPhone}`
                          : "Vous devez renseigner votre numéro de téléphone dans votre profil"
                      }
                    />
                  )}
                </RadioGroup>
              </FormControl>
            )}
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperWithSpacing}>
          <Box className={classes.inputWithTooltip}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendInvitations}
                    onChange={toggleSendInvitations}
                    name="checkedC"
                    color="primary"
                  />
                }
                label="Envoyer les invitations par email"
              />
            </FormGroup>
          </Box>
        </Paper>
      </Grid>
      {showPickContact && (
        <LinkClientDialog
          handleClose={handleCloseClientDialog}
          client={selectedClient}
          clientIdDisabled={disabledClientId}
        />
      )}
    </Grid>
  );
}

export default inject("sessionStore")(observer(props => <SignatureSignatories {...props} />));
